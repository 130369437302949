import { useState } from 'react';
import ModalNote from '../../Components/ModalNote';
import apiConfig from "../../apiConfig.json";

const EquipForm = ({accessToken, onFormSubmit}) => {

  const [garageNumber, setGarageNumber] = useState();
  const [equipmentType, setEquipmentType] = useState('');
  const [isEquipTypeSelected, setIsEquipTypeSelected] = useState('');
  const [vinNumber, setVinNumber] = useState('');
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [isModalNoteOpened, setIsModalNoteOpened] = useState(false);

  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [garageNumberError, setGarageNumberError] = useState({text: 'Заполните это поле.', state: false});
  const [equipmentTypeError, setEquipmentTypeError] = useState({text: 'Выберите тип оборудования.', state: false});
  const [vinNumberError, setVinNumberError] = useState({text: 'Заполните это поле.', state: false});
  const [brandError, setBrandError] = useState({text: 'Заполните это поле.', state: false});
  const [modelError, setModelError] = useState({text: 'Заполните это поле.', state: false});
  const [submitError, setSubmitError] = useState({text: "Заполните все поля, чтобы продолжить.", state: false});
  const [existingEquipError, setExistingEquipError] = useState({text: "Такой пользователь уже существует.", state: false})
  const EQUIPS = [
    'EXCAVATOR',
    'BULLDOZER',
    'CRANE',
    'GRADER',
    'RINK',
    'TRUCK',
    'REFUELER',
    'AUTO',
    'BUS'
  ];

  const handleGarageNumberChange = (event) => {
    setGarageNumber(event.target.value);
  }

  const handleInputEquipmentChange = (type) => {
    setEquipmentTypeError(prevError => ({...prevError, state: false}));
    setEquipmentType(type);
    setIsEquipTypeSelected(type);
  }

  const handleVinNumberChange = (event) => {
    setVinNumber(event.target.value);
  }

  const handleBrandChange = (event) => {
    setBrand(event.target.value);
  }

  const handleModelChange = (event) => {
    setModel(event.target.value);
  }

  const handleCloseNoteModal = () => {
    setIsModalNoteOpened(false);
  }

  const handleBlur = (event, setError) => {
    if(!event.target.value) {
      setError(prevError => ({...prevError, state: true}));
    } else {
      setError(prevError => ({...prevError, state: false}));
    }
  };

  const handleIsEpmtyError = (event, setError) => {
    if(!event.target.value.length) {
      setError(prevError => ({...prevError, state: true}));
    } else {
      setError(prevError => ({...prevError, state: false}));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!equipmentType) {
      setEquipmentTypeError(prevError => ({...prevError, state: true}));
    } else {
      setEquipmentTypeError(prevError => ({...prevError, state: false}));
    }
    if(!garageNumber || !equipmentType || !vinNumber || !brand || !model) {
      setSubmitError(prevError => ({...prevError, state: true}));
      return;
    }
    setSubmitError(prevError => ({...prevError, state: false}));
    const equipmentInfo = {
      model: model,
      brand: brand,
      vin: vinNumber,
      mac: "",
      warrantyNumber: garageNumber,
      stateEquipment: "OK",
      // lastPlace: null,
      // lastShift: null,
      // dailyTripCount: 0,
      equipmentType: equipmentType
    };

    const formData = equipmentInfo;
    // console.log(JSON.stringify(formData))
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_EQUIPMENT}`, {
      method: 'POST',
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      if (response.status === 409) {
        setExistingEquipError(prevState => ({
          ...prevState,
          state: true
        }))
        return;
      }
      if(response.ok) {
        setExistingEquipError(prevState => ({
          ...prevState,
          state: false
        }))
        onFormSubmit();
        setIsModalNoteOpened(true);
        console.log('new equipment post worked!');
      }
    })
    .catch(error => {
      console.log('error posting new equipment: ', error);
    })
  };

  return(
    <>
      <section className="addition-form-wrapper">
        {/* {isFormSubmitted === false ? (  */}
        <>
        <form className='addition-form' onSubmit={handleSubmit} method='post'>
          <div className="addition-form__input-wrapper">
            <label htmlFor='garageNumber'>Гаражный номер</label>

            <input
              id='garageNumber'
              className='addition-form-input'
              placeholder='Введите гаражный номер'
              value={garageNumber}
              onChange={(e) => {handleGarageNumberChange(e); handleIsEpmtyError(e, setGarageNumberError)}}
              onBlur={(e) => handleBlur(e, setGarageNumberError)}
              style={garageNumberError.state ? {border: '3px solid #a7434f'} : {border: '1px solid #211b27'} }
              />
            {garageNumberError.state &&
              <p className="validation-error-message">{garageNumberError.text}</p>
            }
          </div>

          <div className="addition-form__input-wrapper">
            <label>Тип оборудования</label>

              <div className="button-type-list addition-button-type-list addition-equipment-button-list">
            {EQUIPS.map((equip, index) => {
              return (
                <button key={index} type='button' className={`addition-button-add-equipment modal-change-equipment technic-type-${isEquipTypeSelected === equip ? 'white' : 'black'}-${equip.toLowerCase()} ${isEquipTypeSelected === equip ? 'selected' : ''}`}
                onClick={(e) => {handleInputEquipmentChange(e.target.value); }}
                value={equip}
                />
              )
            })}
            </div>
            {equipmentTypeError.state &&
              <p className="validation-error-message">{equipmentTypeError.text}</p>
            }
          </div>

          <div className="addition-form__input-wrapper">
            <label htmlFor='vin'>VIN</label>
            <input
              id='vin'
              className='addition-form-input'
              placeholder='Введите VIN'
              value={vinNumber}
              onChange={(e) => {handleVinNumberChange(e); handleIsEpmtyError(e, setVinNumberError)}}
              onBlur={(e) => handleBlur(e, setVinNumberError)}
              style={vinNumberError.state ? {border: '3px solid #a7434f'} : {border: '1px solid #211b27'} }
            />
            {vinNumberError.state &&
              <p className="validation-error-message">{vinNumberError.text}</p>
            }
          </div>

           <div className="addition-form__input-wrapper">

            <label htmlFor='brand'>Марка</label>
            <input
              id='brand'
              className='addition-form-input'
              placeholder='Введите марку'
              value={brand}
              onChange={(e) => {handleBrandChange(e); handleIsEpmtyError(e, setBrandError)}}
              onBlur={(e) => handleBlur(e, setBrandError)}
              style={brandError.state ? {border: '3px solid #a7434f'} : {border: '1px solid #211b27'} }
            />
            {brandError.state &&
              <p className="validation-error-message">{brandError.text}</p>
            }
          </div>

          <div className="addition-form__input-wrapper">
            <label htmlFor='model'>Модель</label>

            <input
              id='model'
              className='addition-form-input'
              placeholder='Введите модель'
              value={model}
              onChange={(e) => {handleModelChange(e); handleIsEpmtyError(e, setModelError)}}
              onBlur={(e) => handleBlur(e, setModelError)}
              style={modelError.state ? {border: '3px solid #a7434f'} : {border: '1px solid #211b27'} }
              />
              {modelError.state &&
              <p className="validation-error-message">{modelError.text}</p>
              }
          </div>

          <div className="addition-submit-__wrapper">
          {existingEquipError.state &&
            <p className="validation-error-message">{existingEquipError.text}</p>
          }
          {submitError.state &&
                <p className="validation-error-message">{submitError.text}</p>
              }
            <button className='button-submit-direction' type='submit'>Добавить оборудование</button>
          </div>
        </form>
        </>
{/*
      ) : (
        <div className="form-submitted-notification__container form-submitted-equipment__wrapper">
          <p className="form-submitted-note form-submitted-equipment-note">Оборудование создано!</p>
        </div>
      )
    } */}
      </section>
      {isModalNoteOpened &&
        <ModalNote
        note='Оборудование создано! Можете скопировать данные:'
        vin={vinNumber}
        garageNumber={garageNumber}
        onClose={handleCloseNoteModal}
         />
      }
    </>
  )
};

export default EquipForm;
