import "leaflet/dist/leaflet.css";
import './modalShiftTable.css';
import { createPortal } from 'react-dom';
import { useCallback, useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker} from "react-leaflet";
import L, { icon } from "leaflet";
import CalendarHeatmap from 'react-calendar-heatmap';
import { subMonths, format, parseISO, subWeeks, isWithinInterval } from 'date-fns';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ModalDelete from "../../Components/ModalDelete";
import ModalNote from "../../Components/ModalNote";
import CustomTooltip from "./CustomTooltip";
import apiConfig from '../../apiConfig.json';
import avatar from '../../Images/avatar.svg';
import img from '../../Images/Equipment/excavator_32.png';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const EquipInfoModal = (props) => {

  const { show, onClose, equip,  accessToken, warrantyNumber, user, equipmentType, imagesArray, date, start, end, direction, place } = props;
  const modalRef = useRef(null);
  const svgRef = useRef(null);
  const modalDeleteRef = useRef(null);
  const modalInfoRef = useRef(null);
  const [currentShow, setCurrentShow] = useState(show);
  const [editingMode, setEditiongMode] = useState(false);
  const [equipmentList, setEquipmentList] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
  const [isModalDeleteOpened, setIsModalDeleteOpened] = useState(false);
  const [isDeleteUserActionEnd, setIsDeleteUserActionEnd] = useState(false);
  const [isModalNoteOpened, setIsModalNoteOpened] = useState(false);
  const [isFetchError, setIsFetchError] = useState({text: "", state: false});
  const [tooltipData, setTooltipData] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState({top: 0, left: 0});
  const [showTooltip, setShowTooltip] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [recentlyWorked, setRecentlyWorked] = useState(false);

  // console.log(equipmentType)
  // console.log(equip)
  const [equipState, setEquipState] = useState({
    loading:false,
    equipInfo: null
  })
  const [equipShiftsState, setEquipShiftsState] = useState({
    loading: false,
    equipShifts: null,
    totalElements: 0
  })

  const placeModalElem = document.querySelector('.body-wrapper');

  useEffect(() => {
    if (show && equip &&  equip !== 1324234) {
      if (show && equip && equip !== 1324234 ) {
        const equipUrl = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_EQUIP_INFORMATION}${equip}`;

        fetch(equipUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            setEquipState({
              loading: true,
              equipInfo: data,
            });
          })
          .catch((error) => {
            console.error('Error fetching equip data:', error);
            setEquipState({
              loading: false,
              equipInfo: null,
            });
          });
      }
    };
  }, [show, accessToken, equip]);


  useEffect(() => {
    if (show && equip) {
      console.log(JSON.stringify(
        Object({
        "filters": {
          "vin": [`${equip}`]
        }
      })))
    const url = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_DATA_TABLE}?page=0&size=400&sortBy=start&sortOrder=desc&operator=and`;
    // console.log(url)
    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        Object({
        "filters": {
          "vin": [`${equip}`]
        }
      }))
    })
      .then(response => {console.log(response); return response.json() })
      .then(data => {
        // console.log(data)
        setEquipShiftsState({
          loading: true,
          equipShifts: data,
          currentPage: data.number,
          totalElements: data.totalElements
        });
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
        setEquipShiftsState({
          loading: false,
          equipShifts: null,
          currentPage: 0,
          totalPages: 0
        });
      });
  }},[accessToken, equip]);

  console.log('equipShiftsState: ', equipShiftsState)

  const toggleMap = (coordinates) => {
    setShowMap(!showMap);
  }

  const handleImageCountPrev = () => {
    if (imageCount >= 0) {
    setImageCount(imageCount - 1);
    }
    // console.log(imageCount)
  }

  const handleImageCountNext = () => {
    if (imageCount <= imagesArray.length - 1) {
    setImageCount(imageCount + 1)
    }
    // console.log(imageCount)
  }

  const handleOpenDeleteUser = () => {
    setIsDeleteUserOpen(true);
    setIsModalDeleteOpened(true);
  }

  const handleCloseDeleteModal = () => {
    setIsModalDeleteOpened(false);
    // console.log('deleted')
  }

  const handleCloseNoteModal = (setCurrentAction) => {
    setCurrentAction(false);
    setIsModalNoteOpened(false);
    // console.log('closed')
  }

  const handleDeleteEquipment = () => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.DELETE_EQUIPMENT}${equip}`, {
      method: "DELETE",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
    .then (response => {
      if(response.ok) {
        console.log('equip was deleted successfully!');
        setIsDeleteUserOpen(false);
        handleCloseDeleteModal();
        setIsDeleteUserActionEnd(true);
        setIsModalNoteOpened(true);
      }
    })
    .catch (error => {
        setIsDeleteUserOpen(false);
        handleCloseDeleteModal();
        setIsDeleteUserActionEnd(true);
        setIsModalNoteOpened(true)
        setIsFetchError({text: error, state: true})
      console.log('error deleting equip: ', error);
    });
  }

  // console.log(modalRef, modalInfoRef, modalDeleteRef)

  useEffect(() => {
      const handleOutsideClick = (event) => {
        if (modalInfoRef.current && !modalInfoRef.current.contains(event.target)) {
          onClose();
          setEquipState({
            loading: false,
            equipInfo: null,
          });
        }
      };

      document.addEventListener('mousedown', handleOutsideClick);

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
  }, [onClose]);

  const equipData = equipState.equipInfo;
  console.log('equipData: ', equipData)

  useEffect(() => {
    if (svgRef.current) {
      const svgElement = svgRef.current.querySelector('svg');
      if (svgElement) {
        svgElement.setAttribute('viewBox', '90 0 50 100');
      }
    }
  }, []);

  const getImageForEquipmentType = (equipmentType) => {
    switch (equipmentType) {
      case 'EXCAVATOR':
        return require('../../Images/Equipment/excavator_32.png');
      case 'BULLDOZER':
        return require('../../Images/Equipment/bulldozer_32.png');
      case 'CRANE':
        return require('../../Images/Equipment/crane_32.png');
      case 'GRADER':
        return require('../../Images/Equipment/grader_32.png');
      case 'RINC':
        return require('../../Images/Equipment/rinc_32.png');
      case 'RINK':
        return require('../../Images/Equipment/rink_32.png');
      case 'TRUCK':
        return require('../../Images/Equipment/truck_32.png');
      case 'POOKER':
        return require('../../Images/Equipment/camera_10.png');
      case 'AUTO':
        return require('../../Images/Equipment/auto_32.png');
      case 'REFUELER':
        return require('../../Images/Equipment/refueler_512.png');
      case 'BUS':
        return require('../../Images/Equipment/bus_512.png');
      default:
        return require('../../Images/Equipment/excavator_32.png');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleEditingMode = () => {
    setEditiongMode(!editingMode);
  }

  const handleCloseModal = () => {
    setEquipState({
      loading: false,
      equipInfo: null,
    });
    onClose();
  }

  const lastPointIcon = new L.icon({
    iconUrl: require('../../Images/marker.png'),
    iconSize: [15, 20],
    iconAnchor: [1, 20],
  });

  if (!show) return null;
  return (
    <>
    {
      createPortal(
        <section className='modal-wrapper'>
          <div className={editingMode ? 'modal-wrapper__modal modal-wrapper__modal--editing' :'modal-wrapper__modal'} ref={modalInfoRef}>
            {
              equipState.loading === false ? (
                  equip === 1324234 ? (
                    <span className='modal__header'>Информация не найдена</span>
                  ) : (
                    <span className='modal__header'>Идет загрузка данных</span>
                  )
              ) : (
                <>
                  {
                    (() => {

                        const currentDate = new Date();
                        const startDate = subWeeks(currentDate, 1);
                        const endDate = currentDate;

                        const weekHasShift = equipData.shiftsInfo.some((shift, index) => {
                            const hasShift = isWithinInterval(parseISO(shift.shiftDate), {start: startDate, end: endDate})
                            return hasShift
                          })

                      return(
                        <>
                          {
                            weekHasShift ? (
                              <>
                              {
                                editingMode ? (
                                  <span className='modal__header modal__header--dark'>Редактирование</span>
                                ) : (
                                  <>
                                    <div className="modal__header">
                                      <div className="modal__info-wrapper">
                                        <img className="modal__equip-image" src={getImageForEquipmentType(equipmentType)} width={30} height={30} alt="equipment"/>
                                        <span className='modal__name modal__name--equip modal__name--active'>{equipData.equipInfo.warrantyNumber}</span>
                                        {/* <div className="modal__button-wrapper">
                                          <button className="modal_button modal_button--edit-modal" onClick={handleEditingMode}></button>
                                          <button className="modal_button modal_button--delete-modal" onClick={handleOpenDeleteUser}></button>
                                        </div> */}
                                        <button className="modal__button--close-modal" onClick={handleCloseModal}></button>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                              </>
                            ) : (
                              <>
                              {
                                editingMode ? (
                                  <span className='modal__header modal__header--dark'>Редактирование</span>
                                ) : (
                                  <>
                                    <div className="modal__header">
                                      <div className="modal__info-wrapper">
                                        <img className="modal__equip-image" src={getImageForEquipmentType(equipmentType)} width={30} height={30} alt="equipment"/>
                                        <span className='modal__name modal__name--equip'>{equipData.equipInfo.warrantyNumber}</span>
                                        {/* <div className="modal__button-wrapper">
                                          <button className="modal_button modal_button--edit-modal" onClick={handleEditingMode}></button>
                                          <button className="modal_button modal_button--delete-modal" onClick={handleOpenDeleteUser}></button>
                                        </div> */}
                                        <button className="modal__button--close-modal" onClick={handleCloseModal}></button>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                              </>
                            )
                          }
                        </>
                      )
                    })()
                  }

                {
                  showMap ? (
                    <>
                      <div className='modal__information-container information-container__map'>
                        {
                          equipData.lastRoute.geo === null ? (
                            <>
                              <MapContainer center={[59.888877, 30.482488]} zoom={5} className='leaflet-map-opened'>
                                <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                zoomControl="false"/>
                                <Marker position={[59.888877, 30.482488]} icon={lastPointIcon} />
                                <button className="information-container__button-open-map" type="button" onClick={() =>toggleMap([59.5597850, 30.0849539])}></button>
                              </MapContainer>

                            </>
                          ) : (
                            <>
                              <MapContainer center={equipData.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim()))} zoom={5} className='leaflet-map-opened'>
                                <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                zoomControl="false"/>
                                <Marker position={equipData.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim()))} icon={lastPointIcon} />
                                <button className="information-container__button-open-map" type="button" onClick={() => toggleMap(equipData.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim())))}></button>
                            </MapContainer>
                          </>
                          )
                        }
                        <button className="information-container__button-map-close" onClick={toggleMap}>Закрыть</button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='modal__information-container information-container information-container--equip'>
                        <div className='information-container__avatar'>
                          {
                            editingMode ? (
                              <>
                                  <div className="information-container__gallery gallery">
                                    <div></div>
                                    {
                                      imagesArray === undefined || imagesArray.length === 0 ? (
                                        <img src={getImageForEquipmentType(equipmentType)} alt="equip"  width={195} height={150}/>
                                      ) : (
                                        <>
                                          <button className="gallery__button gallery__button--prev" onClick={handleImageCountPrev} disabled={imageCount === 0}></button>
                                          <img src={imagesArray[imageCount].value} alt="equip images" width={195} height={150} loading="lazy"/>
                                          <button className="gallery__button gallery__button--next" onClick={handleImageCountNext} disabled={imageCount === imagesArray.length - 1}></button>
                                        </>
                                      )
                                    }
                                  </div>
                                  <dl>
                                    <div>
                                      <dt className="personal-info__topic">Датчик оборудования</dt>
                                      {
                                        equipData.equipInfo.mac ? (
                                          <input
                                          className="personal-info__input"
                                          value={equipData.equipInfo.mac}></input>
                                        ) : (
                                          <input
                                          className="personal-info__input"
                                          value={equipData.equipInfo.mac}></input>
                                        )
                                      }
                                    </div>
                                    <div>
                                      <dt className="personal-info__topic">Обращения в поддержку</dt>
                                      <dd className="personal-info__description personal-info__description--emphasized">-</dd>
                                    </div>
                                </dl>
                              </>
                            ) : (
                              <>
                              <div className="information-container__gallery gallery">
                                <div></div>
                                {
                                      imagesArray === undefined || imagesArray.length === 0 ? (
                                        <img src={getImageForEquipmentType(equipmentType)} alt="equip"  width={195} height={150}/>
                                      ) : (
                                        <>
                                          <button className="gallery__button gallery__button--prev" onClick={handleImageCountPrev} disabled={imageCount === 0}></button>
                                          <img src={imagesArray[imageCount].value} alt="equip images" width={195} height={150} loading="lazy"/>
                                          <button className="gallery__button gallery__button--next" onClick={handleImageCountNext} disabled={imageCount === imagesArray.length - 1}></button>
                                        </>
                                      )
                                    }
                              </div>
                              </>
                            )
                          }
                        </div>
                        <div className='information-container__personal-info personal-info'>
                          <dl>
                            <div>
                              {
                                editingMode ? (
                                  <>
                                    <dt className="personal-info__topic">Гараж. номер</dt>
                                    <input
                                    className="personal-info__input"
                                    value={equipData.equipInfo.vin}
                                   ></input>
                                  </>
                                ) : (
                                  <>
                                    <dt className="personal-info__topic">Гараж. номер</dt>
                                    <dd className="personal-info__description">{equipData.equipInfo.warrantyNumber}</dd>
                                  </>
                                )
                              }
                            </div>
                            <div>
                              {
                                editingMode ? (
                                  <>
                                    <dt className="personal-info__topic">VIN</dt>
                                    <input
                                    className="personal-info__input"
                                    value={equipData.equipInfo.vin}></input>
                                  </>
                                ) : (
                                  <>
                                    <dt className="personal-info__topic">VIN</dt>
                                    <dd className="personal-info__description">{equipData.equipInfo.vin}</dd>
                                  </>
                                )
                              }
                            </div>
                            <div>
                              {
                                editingMode ? (
                                  <>
                                    <dt className="personal-info__topic">Марка</dt>
                                    <input
                                    className="personal-info__input"
                                    value={equipData.equipInfo.brand}></input>
                                  </>
                                ) : (
                                  <>
                                    <dt className="personal-info__topic">Марка</dt>
                                    <dd className="personal-info__description">{equipData.equipInfo.brand}</dd>
                                  </>
                                )
                              }
                            </div>
                            <div>
                              {
                                editingMode ? (
                                  <>
                                    <dt className="personal-info__topic">Модель</dt>
                                    <input
                                    className="personal-info__input"
                                    value={equipData.equipInfo.model}></input>
                                  </>
                                ) : (
                                  <>
                                    <dt className="personal-info__topic">Модель</dt>
                                    <dd className="personal-info__description">{equipData.equipInfo.model}</dd>
                                  </>
                                )
                              }
                            </div>
                          </dl>
                        </div>
                        <div className='information-container__other-parameters'>
                          { !editingMode &&
                            <dl>
                              <div>
                                <dt className="personal-info__topic">Датчик оборудования</dt>
                                {
                                  equipData.equipInfo.mac === null ? (
                                    <dd className="personal-info__description personal-info__description--emphasized">-</dd>
                                  ) : (
                                    <dd className="personal-info__description personal-info__description--emphasized">{equipData.equipInfo.mac}</dd>
                                  )
                                }
                              </div>
                              <div>
                                <dt className="personal-info__topic">Обращения в поддержку</dt>
                                <dd className="personal-info__description personal-info__description--emphasized">-</dd>
                              </div>
                          </dl>
                          }
                        </div>
                        <div className='information-container__maps information-container__maps--equip'>
                            <p className="personal-info__topic">Последняя известная геопозиция</p>
                            <div className='information-container__map-coordinates-wrapper'>
                              <div>
                                {
                                  equipData.lastRoute.geo === null ? (
                                    <MapContainer center={[59.888877, 30.482488]} zoom={5} className='equip-leaflet-container'>
                                      <TileLayer
                                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                      zoomControl="false"/>
                                      <Marker position={[59.888877, 30.482488]} icon={lastPointIcon} />
                                      <button className="information-container__button-open-map" type="button" onClick={() =>toggleMap([59.888877, 30.482488])}></button>
                                    </MapContainer>
                                  ) : (
                                    <MapContainer center={equipData.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim()))} zoom={5} className='equip-leaflet-container'>
                                      <TileLayer
                                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                      zoomControl="false"/>
                                      <Marker position={equipData.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim()))} icon={lastPointIcon} />
                                      <button className="information-container__button-open-map" type="button" onClick={() => toggleMap(equipData.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim())))}></button>
                                    </MapContainer>
                                  )
                                }
                              </div>
                              <dl className='information-container__coordinates'>
                                <div>
                                  <dt className="personal-info__topic">Широта</dt>
                                  {
                                    equipData.lastRoute.geo === null ? (
                                      <dd className="personal-info__description">59.5597850</dd>
                                    ) : (
                                      <dd className="personal-info__description">{equipData.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim()))[0]}</dd>
                                    )
                                  }
                                </div>
                                <div>
                                  <dt className="personal-info__topic">Долгота</dt>
                                  {
                                    equipData.lastRoute.geo === null ? (
                                      <dd className="personal-info__description">30.0849539</dd>
                                    ) : (
                                      <dd className="personal-info__description">{equipData.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim()))[1]}</dd>
                                    )
                                  }
                                </div>
                              </dl>
                          </div>
                        </div>
                        {
                          (() => {
                            const groupedData = equipData.shiftsInfo.reduce((acc, { shiftDate, tripNum }) => {
                              if (!acc[shiftDate]) {
                                  acc[shiftDate] = 0;
                              }
                              acc[shiftDate] += tripNum;
                              return acc;
                            }, {});

                            const heatmapData = Object.keys(groupedData).map(date => ({
                                date: date, value: groupedData[date]
                            }));

                            const maxValue = Math.max.apply(null, heatmapData.map((data) => data.value));
                            const averageValue = maxValue / 5;

                            const handleMouseEnter = (event, data) => {
                              setTooltipData(data);
                              setTooltipPosition({ top: event.clientY - 200, left: event.clientX - 200 });
                              setShowTooltip(true);
                            };

                            const handleMouseLeave = () => {
                              setShowTooltip(false);
                            };

                            const currentDate = new Date();
                            const startDate = subMonths(currentDate, 4);
                            const endDate = currentDate;

                            const heatmapDataFiltered = heatmapData.filter(item => {
                              const itemDate = new Date(item.date);
                              return itemDate >= startDate && itemDate <= endDate;
                            });

                            const uniqueProductiveDays = [...new Set(Object.keys(groupedData))];
                            const totalDaysInLastThreeMonths = [];
                            for(let i = 0; i < 3; i++) {
                              const month = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
                              const daysInMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate();
                              totalDaysInLastThreeMonths.push(daysInMonth);
                            }

                            const totalDaysThreeMonth = totalDaysInLastThreeMonths.reduce((acc, days) => acc + days, 0);

                            const productivePercent = (uniqueProductiveDays.length / totalDaysThreeMonth) * 100;
                            const unproductivePercent = 100 - productivePercent;

                            const DATA = {
                              labels: ['Продуктивные дни', 'Непродуктивные дни'],
                              datasets: [
                                {
                                  label: 'Продуктивность',
                                  data: [productivePercent.toFixed(2), unproductivePercent.toFixed(2)],
                                  backgroundColor: ['#F0D07E', '#F0D07EB2'],
                                  hoverBackgroundColor: ['#F0D07E', '#F0D07EB2'],
                                },
                              ],
                            };

                            const options = {
                              responsive: true,
                              cutout: '65%',
                              plugins: {
                                datalabels: {
                                  formatter: (value) => `${value}%`,
                                  color: '#211B27CC',
                                  font: {
                                    weight: 'normal',
                                    size: 9
                                  },
                                },
                                legend: {
                                  display: false,
                                  position: 'top',
                                },
                              },
                            };

                            const tripsPerDay = heatmapDataFiltered.reduce((acc, { date, value }) => {
                              acc[date] = (acc[date] || 0) + value;
                              return acc;
                            }, {});


                            const totalTrips = Object.values(tripsPerDay).reduce((sum, trips) => sum + trips, 0);
                            const totalDays = Object.keys(tripsPerDay).length;
                            const averageTripsPerDay = totalDays ? (totalTrips / totalDays) : 0;


                            return(
                              <>
                                <div className='information-container__productivity-months information-container__productivity-months--equip' ref={svgRef}>
                                <p className="personal-info__topic">Продуктивность за четыре месяца</p>
                                <CalendarHeatmap
                                  startDate={startDate}
                                  endDate={endDate}
                                  values={heatmapDataFiltered}
                                  showWeekdayLabels={true}
                                  classForValue={(value) => {
                                    if (!value || value.value === 0) {
                                      return 'color-empty calendar-rect';
                                    }
                                    if (value.value > averageValue * 4) {
                                      return 'color-scale-4 calendar-rect';
                                    } else if (value.value > averageValue * 3) {
                                      return 'color-scale-3 calendar-rect';
                                    } else if (value.value > averageValue * 2) {
                                      return 'color-scale-2 calendar-rect';
                                    } else {
                                      return 'color-scale-1 calendar-rect';
                                    }
                                  }}
                                  tooltipDataAttrs={(value) => ({
                                    onMouseEnter: (e) => handleMouseEnter(e, value),
                                    onMouseLeave: handleMouseLeave
                                  })}
                                />
                                {showTooltip && <CustomTooltip data={tooltipData} position={tooltipPosition} averageValue={averageValue}/>}
                                </div>
                                <div className='information-container__productivity information-container__productivity--equip'>
                                  <div className="information-container__doughnut-wrapper" >
                                    <Doughnut data={DATA} options={options} height={150} width={150}/>
                                    <div className="information-container__doughnut-info doughnut-info">
                                      <p className="doughnut-info__num">{uniqueProductiveDays.length}</p>
                                      <p>дни продуктивности</p>
                                    </div>
                                  </div>
                                  <dl>
                                    <div>
                                      <dt className="personal-info__topic">Ср. кол-во рейсов в день</dt>
                                      <dd className="personal-info__description">{averageTripsPerDay.toFixed(0)}</dd>
                                    </div>
                                    <div>
                                      <dt className="personal-info__topic">Ср. прорцент продуктив./месяц</dt>
                                      <dd className="personal-info__description">{Math.round(productivePercent)}%</dd>
                                    </div>
                                  </dl>
                                </div>
                              </>
                            );
                          })()
                        }
                        {
                          editingMode ? (
                            <>
                              <div className="information-container__previous-shifts--editing previous-shifts">
                                <div className="personal-info__wrapper">
                                  <p className="personal-info__topic">Текущий рейс:</p>
                                </div>
                                <dl className="current-shift__container">
                                  <div>
                                    <dt className="personal-info__topic">Водитель</dt>
                                    {
                                      user === undefined ? (
                                        <dd className="personal-info__description equip-information">
                                          -
                                        </dd>
                                      ) : (
                                        <dd className="personal-info__description equip-information">
                                          {user}
                                        </dd>
                                      )
                                    }
                                  </div>
                                  <div>
                                    <dt className="personal-info__topic">Дата</dt>
                                    {
                                      date === undefined ? (
                                        <dd className="personal-info__description">-</dd>
                                      ) : (
                                        <dd className="personal-info__description">{date}</dd>
                                      )
                                    }
                                  </div>
                                  <div>
                                    <dt className="personal-info__topic">Время</dt>
                                    {
                                      start === undefined && end === undefined ? (
                                        <dd className="personal-info__description">-</dd>
                                      ) : (
                                        <dd className="personal-info__description">{start}-{end}</dd>
                                      )
                                    }
                                  </div>
                                  <div>
                                    <dt className="personal-info__topic">Направление</dt>
                                    {
                                      direction === undefined ? (
                                        <dd className="personal-info__description">-</dd>
                                      ) : (
                                        <dd className="personal-info__description">{direction}</dd>
                                      )
                                    }
                                  </div>
                                  <div>
                                    <dt className="personal-info__topic">Участок</dt>
                                    {
                                      place === undefined ? (
                                        <dd className="personal-info__description">-</dd>
                                      ) : (
                                        <dd className="personal-info__description">{place}</dd>
                                      )
                                    }
                                  </div>
                                </dl>
                                <p className="personal-info__topic">Предыдущие смены:</p>
                                <ul className="previous-shifts__list">
                                {
                                  equipShiftsState.loading === false ? (
                                    <p>Смены загружаются</p>
                                  ) : (
                                  equipShiftsState.equipShifts.content.map((equipShift, index) => {
                                    return(
                                    <li key={index} className="previous-shifts__item">
                                        <div className="equip-information">
                                          <img src={getImageForEquipmentType(equipShift.equipment.equipmentType)} width={30} height={30} alt="equip" />
                                          <p>{equipShift.equipment.warrantyNumber}</p>
                                        </div>
                                        <p>{formatDate(equipShift.start)}</p>
                                    </li>
                                    )
                                  })
                                )
                                }
                                </ul>
                                {
                                  editingMode && <button className="personal-info__button-safe" onClick={handleEditingMode}>Coxpaнить</button>
                                }
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="information-container__current-shift personal-info current-shift">
                                <div className="personal-info__wrapper">
                                  <p className="personal-info__topic">Текущий рейс:</p>
                                  {/* <button className="previous-shifts__button"></button> */}
                                </div>
                                <dl className="current-shift__container">
                                  <div>
                                    <dt className="personal-info__topic">Водитель</dt>
                                    {
                                      user === undefined ? (
                                        <dd className="personal-info__description equip-information">
                                          -
                                        </dd>
                                      ) : (
                                        <dd className="personal-info__description equip-information">
                                          {user}
                                        </dd>
                                      )
                                    }
                                  </div>
                                  <div>
                                    <dt className="personal-info__topic">Дата</dt>
                                    {
                                      date === undefined ? (
                                        <dd className="personal-info__description">-</dd>
                                      ) : (
                                        <dd className="personal-info__description">{date}</dd>
                                      )
                                    }
                                  </div>
                                  <div>
                                    <dt className="personal-info__topic">Время</dt>
                                    {
                                      start === undefined && end === undefined ? (
                                        <dd className="personal-info__description">-</dd>
                                      ) : (
                                        <dd className="personal-info__description">{start}-{end}</dd>
                                      )
                                    }
                                  </div>
                                  <div>
                                    <dt className="personal-info__topic">Направление</dt>
                                    {
                                      direction === undefined ? (
                                        <dd className="personal-info__description">-</dd>
                                      ) : (
                                        <dd className="personal-info__description">{direction}</dd>
                                      )
                                    }
                                  </div>
                                  <div>
                                    <dt className="personal-info__topic">Участок</dt>
                                    {
                                      place === undefined ? (
                                        <dd className="personal-info__description">-</dd>
                                      ) : (
                                        <dd className="personal-info__description">{place}</dd>
                                      )
                                    }
                                  </div>
                                </dl>
                              </div>
                              <div className="information-container__previous-shifts previous-shifts previous-shifts--equip">
                                <p className="personal-info__topic">Предыдущие смены:</p>
                                <ul className="previous-shifts__list previous-shift__list--equip">
                                {
                                  equipShiftsState.loading === false ? (
                                    <p>Смены загружаются</p>
                                  ) : (
                                  equipShiftsState.equipShifts.content.map((equipShift, index) => {
                                    return(
                                    <li key={index} className="previous-shifts__item">
                                        <div className="equip-information">
                                          <img src={getImageForEquipmentType(equipShift.equipment.equipmentType)} width={30} height={30} alt="equip" />
                                          <p>{equipShift.equipment.warrantyNumber}</p>
                                        </div>
                                        <p>{formatDate(equipShift.start)}</p>
                                        {/* <button className="previous-shifts__button"></button> */}
                                    </li>
                                    )
                                  })
                                )
                                }
                                </ul>
                                {
                                  editingMode && <button className="personal-info__button-safe" onClick={handleEditingMode}>Coxpaнить</button>
                                }
                              </div>
                            </>
                          )
                        }
                      </div>
                    </>
                  )
                }
                </>
              )
            }
          </div>
          {
            isDeleteUserOpen && isModalDeleteOpened ? (
               createPortal (
                  <ModalDelete
                  modalRef={modalDeleteRef}
                  message='Вы уверены, что хотите удалить оборудование? Отменить действие будет невозможно.'
                  actionText='Да, удалить'
                  action={handleDeleteEquipment}
                  onClose={handleCloseDeleteModal}/>,
                  placeModalElem
                )
            ) : (
                isDeleteUserActionEnd && isModalNoteOpened && (
                  !isFetchError.state ? (
                    createPortal (
                      <ModalNote
                      note="Пользователь успешно удалён!"
                      type="success"
                      onClose={() => handleCloseNoteModal(setIsDeleteUserActionEnd)}
                      />,
                      placeModalElem
                    )

                  ) : (
                    createPortal (
                      <ModalNote
                      note={`Ошибка при удалении пользователя: ${isFetchError.text}`}
                      type="error"
                      onClose={() => handleCloseNoteModal(setIsDeleteUserActionEnd)}
                      />,
                      placeModalElem
                    )
                  )
                )
            )
          }
        </section>,
        placeModalElem
      )
    }
    </>
  )
}

let DefaultIcon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  iconSize: [10, 15],
    iconAnchor: [5, 15],
    popupAnchor: [1, -12],
});
L.Marker.prototype.options.icon = DefaultIcon;

export default EquipInfoModal;


